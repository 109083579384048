var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        top: "5vh",
        "append-to-body": "",
        title: _vm.deptRow
          ? _vm.$t("lang_edit_bept_info")
          : _vm.$t("lang_add_dept_info"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "formRef",
          attrs: {
            model: _vm.deptData,
            "label-suffix": "：",
            rules: _vm.formRules,
            "label-width": "160px",
          },
        },
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_department"),
                            prop: "deptId",
                          },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.deptListData,
                              flat: true,
                              multiple: false,
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                              normalizer: _vm.menuTreeNormalizer,
                              placeholder: _vm.$t("lang_please_select"),
                            },
                            model: {
                              value: _vm.deptData.deptId,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "deptId", $$v)
                              },
                              expression: "deptData.deptId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("lang_duty"), prop: "dutyId" },
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.dutyListData,
                              flat: true,
                              multiple: false,
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                              normalizer: _vm.menuTreeNormalizer,
                              placeholder: _vm.$t("lang_please_select"),
                            },
                            model: {
                              value: _vm.deptData.dutyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "dutyId", $$v)
                              },
                              expression: "deptData.dutyId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_is_it_the_competent_department"),
                        prop: "defaultAble",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择是否是主管部门",
                            clearable: "",
                          },
                          model: {
                            value: _vm.deptData.defaultAble,
                            callback: function ($$v) {
                              _vm.$set(_vm.deptData, "defaultAble", $$v)
                            },
                            expression: "deptData.defaultAble",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: _vm.$t("lang_yes"), value: true },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("lang_no"), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_tenure"),
                            prop: "takeOfficeTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: _vm.$t("lang_please_select_date"),
                            },
                            model: {
                              value: _vm.deptData.takeOfficeTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "takeOfficeTime", $$v)
                              },
                              expression: "deptData.takeOfficeTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_title_level"),
                            prop: "takeOfficeTime",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择职称级别",
                              },
                              model: {
                                value: _vm.deptData.dutyLevel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deptData, "dutyLevel", $$v)
                                },
                                expression: "deptData.dutyLevel",
                              },
                            },
                            _vm._l(
                              _vm.dictionaryCode.professional_title_grade,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级姓名" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择上级姓名",
                              },
                              model: {
                                value: _vm.deptData.nameSuperior,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deptData, "nameSuperior", $$v)
                                },
                                expression: "deptData.nameSuperior",
                              },
                            },
                            _vm._l(_vm.personlist, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "薪资段位", prop: "takeOfficeTime" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入薪资段位",
                            },
                            model: {
                              value: _vm.deptData.salarySegment,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "salarySegment", $$v)
                              },
                              expression: "deptData.salarySegment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "岗位" } },
                        [
                          _c("treeselect", {
                            attrs: {
                              options: _vm.takePostListData,
                              flat: true,
                              multiple: false,
                              "append-to-body": "",
                              "z-index": 2999,
                              "default-expand-level": 1,
                              normalizer: _vm.menuTreeNormalizer,
                              placeholder: "请选择岗位",
                            },
                            model: {
                              value: _vm.deptData.takePost,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "takePost", $$v)
                              },
                              expression: "deptData.takePost",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "司龄（年）",
                            prop: "takeOfficeTime",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm._getAge + " 年") + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "职称" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择职称",
                              },
                              model: {
                                value: _vm.deptData.titleGrade,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deptData, "titleGrade", $$v)
                                },
                                expression: "deptData.titleGrade",
                              },
                            },
                            _vm._l(
                              _vm.dictionaryCode.professional_title,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_employment_status"),
                            prop: "status",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_the_employment_status"
                                ),
                                clearable: "",
                              },
                              model: {
                                value: _vm.deptData.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.deptData, "status", $$v)
                                },
                                expression: "deptData.status",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: _vm.$t("lang_in_office"),
                                  value: "1",
                                },
                              }),
                              !_vm.addFlag
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_leave_office"),
                                      value: "2",
                                    },
                                  })
                                : _vm._e(),
                              !_vm.addFlag
                                ? _c("el-option", {
                                    attrs: {
                                      label: _vm.$t("lang_delete"),
                                      value: "3",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("lang_reason_for_employment"),
                        prop: "takeOfficeReason",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea", rows: 4 },
                        model: {
                          value: _vm.deptData.takeOfficeReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.deptData, "takeOfficeReason", $$v)
                          },
                          expression: "deptData.takeOfficeReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_work_city") } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          options: _vm.proviceList,
                          props: {
                            value: "id",
                            label: "name",
                            children: "childList",
                          },
                        },
                        on: { change: _vm.cityChange },
                        model: {
                          value: _vm.workCityObj,
                          callback: function ($$v) {
                            _vm.workCityObj = $$v
                          },
                          expression: "workCityObj",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.addFlag
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_time_of_resignation"),
                            prop: "leaveOfficeTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: _vm.$t("lang_please_select_date"),
                            },
                            model: {
                              value: _vm.deptData.leaveOfficeTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "leaveOfficeTime", $$v)
                              },
                              expression: "deptData.leaveOfficeTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.addFlag
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("lang_reason_for_resignation"),
                            prop: "leaveOfficeReason",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea", rows: 4 },
                            model: {
                              value: _vm.deptData.leaveOfficeReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.deptData, "leaveOfficeReason", $$v)
                              },
                              expression: "deptData.leaveOfficeReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }