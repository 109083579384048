














































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import DictApi from '@/api/system/DictApi';
import { getPersonlist } from '@/api/system/PersonApi';
@Component({
  name: 'PersonAddEdit',
  components: {}
})
export default class PersonAddEdit extends Vue {
  // ref
  @Ref() readonly formRef;
  // 弹窗显隐
  @Prop({ default: false }) private visible!: boolean;
  // 当前行信息
  @Prop({ default: undefined }) deptRow;
  // 调入时不展示
  @Prop({ default: false }) addFlag: boolean;
  // 部门下拉列表
  @Prop({ default: [] }) deptListData!: any;
  // 职务下拉
  @Prop({ default: [] }) dutyListData!: any;
  // 岗位下拉
  @Prop({ default: [] }) takePostListData!: any;
  // 公司id
  @Prop({ default: '' }) companyId!: string;

  @Prop({ default: [] }) proviceList: any;

  @Prop({ default: {} }) frontDisplayData: any;
  // 公司id
  @Prop({ default: [] }) groupIds: any;

  dictionaryCode: any = {
    professional_title: [],
    professional_title_grade: []
  };
  personlist: any[] = [];

  // 表单加载
  formLoading: boolean = false;
  // 表单数据
  deptData: any = {};
  workCityObj: any = [];
  // 表单验证规则
  formRules: Record<string, any> = {
    deptId: [
      {
        required: true,
        message: this.$t('lang_please_select_dept'),
        trigger: 'change'
      }
    ],
    dutyId: [
      {
        required: true,
        message: this.$t('lang_please_select_duty'),
        trigger: 'change'
      }
    ],
    defaultAble: [
      {
        required: true,
        message: this.$t('lang_please_select_whether_it_is_the_competent_department'),
        trigger: 'change'
      }
    ],
    // takeOfficeTime: [
    //   {
    //     required: true,
    //     message: this.$t('lang_please_select_the_tenure'),
    //     trigger: 'blur'
    //   }
    // ],
    status: [
      {
        required: true,
        message: this.$t('lang_please_select_the_employment_status'),
        trigger: 'blur'
      }
    ]
    // leaveOfficeTime: [
    //   {
    //     required: true,
    //     message: this.$t('lang_please_select_the_resignation_time'),
    //     trigger: 'blur'
    //   }
    // ]
  };

  created() {
    this.getAllDictionary(); //获取选项字典
    this.getPersonlistFn(); //获取人员列表
    if (this.deptRow) {
      this.deptData = { ...this.deptRow };
      this.workCityObj[0] = 'area-1';
      this.workCityObj[1] = this.deptRow.workProvince;
      this.workCityObj[2] = this.deptRow.workCity;
    } else {
      this.deptData = {
        companyId: this.companyId
      };
    }
  }
  cityChange(value) {
    this.deptData.workProvince = value[1];
    this.deptData.workCity = value[2];
  }
  /**
   * 保存
   */
  save() {
    // 验证表单
    Promise.all([this.formRef.validate()]).then(() => {
      // 关闭弹窗
      this.handleCloseDialog();
      this.$emit('done', this.deptData);
    });
  }

  menuTreeNormalizer(node) {
    let params = {
      id: node.id,
      label: node.name,
      children: node.children,
      isDisabled: false
    };
    if (node.type == '0') {
      params.isDisabled = true;
    }
    return params;
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 获取所有字典
  getAllDictionary() {
    Object.keys(this.dictionaryCode).map(keys => {
      this.getDictionaryCodeData(keys, keys);
    });
  }

  // 查询字典
  getDictionaryCodeData(typeCode, key) {
    let params = {
      typeCode: typeCode
    };
    DictApi.dictList(params).then((reg: any) => {
      if (reg.code == '1') {
        this.dictionaryCode[key] = reg.data;
      } else {
        this.$message.error(reg.message);
      }
    });
  }

  getPersonlistFn() {
    let params = {
      groupIds: [this.groupIds[0]]
    };
    getPersonlist(params)
      .then((res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.personlist = res.data;
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {});
  }

  get _getAge() {
    if (this.frontDisplayData.employmentDate) {
      let date = new Date(this.frontDisplayData.employmentDate);
      const time1: number = date.getTime();
      const time2: number = Date.now();
      const result: number = (time2 - time1) / (1000 * 3600 * 24);
      const year = result / 365;
      return year.toFixed(2);
    } else {
      return 0;
    }
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
